<!--
 * @Author: nice
 * @Date: 2021-11-12 23:24:42
 * @LastEditTime: 2021-12-07 14:12:34
 * @LastEditors: nice
 * @Description: 立即支付
-->
<template>
  <section class="pay-at-once">
    <NavBar title="立即支付" background="blue" />
    <van-notice-bar
      v-show="!platformStatus.enable"
      scrollable
      :text="this.platformStatus.msg"
      background="opacity"
      left-icon="volume-o"
    />
    <div class="main-height-x">
      <div class="card">
        <div class="name-info bBlue">
          <template v-if="accountId === ''">
            <p>
              <span>表号</span>
              <span>{{ this.deviceNumber }}</span>
            </p>
            <p>
              <span>地址</span>
              <span>{{ this.fullAddress }}</span>
            </p>
          </template>
          <template v-else>
            <p>
              <span>姓名</span>
              <span>{{ this.customerName }}</span>
            </p>
            <p>
              <span>身份证号</span>
              <span>{{ this.idCard }}</span>
            </p>
          </template>
        </div>
        <div class="pay-price">
          <p class="blue">
            待支付
            <b>{{ this.amount }}</b>
            元
          </p>
          <div class="remain-time">
            <span>
              支付剩余时间：
              <van-count-down :time="time" format="mm 分 ss 秒" @finish="finish" />
            </span>
          </div>
        </div>
      </div>
      <div class="card marginT10">
        <span class="pay-way">付款方式</span>
        <van-radio-group v-model="radio">
          <van-cell-group>
            <!-- <van-cell title="支付宝" clickable @click="radio = '2'">
              <template #icon>
                <van-icon name="alipay" class="self-icon" color="#1678ff" />
              </template>
              <template #right-icon>
                <van-radio name="2" />
              </template>
            </van-cell> -->
            <van-cell title="微信支付" clickable @click="radio = '1'">
              <template #icon>
                <van-icon name="wechat-pay" class="self-icon" color="#04BE02" />
              </template>
              <template #right-icon>
                <van-radio name="1" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
      <div class="btn-wrap-x">
        <van-button
          v-show="platformStatus.enable"
          type="info"
          class="self-btn"
          :disabled="!this.radio || isOver"
          color="#5196FF"
          @click="surePay"
        >
          确认支付
        </van-button>
      </div>
    </div>
  </section>
</template>

<script>
import { getCustomerNumber4AppVo, payOrder, getPlatformStatus } from '@/services/payServer.js'
import { Toast } from 'vant'
export default {
  data() {
    const { code, state } = this.$route.query
    const {
      amount, // 总计金额
      orderId, // 订单ID
      customerNumber = '', // 用户编号
      accountId = '', // 账户ID -可接口获取 customerAccountId
    } = JSON.parse(state)
    return {
      radio: '1',
      time: 30 * 60 * 1000, // 30 * 60 * 1000
      code,
      fullAddress: '', // 地址 -可接口获取
      deviceNumber: '', // 表编号
      customerName: '', // 用户名称 -可接口获取
      idCard: '', // 身份证 -可接口获取
      customerNumberId: '', // 号ID -可接口获取
      customerAccountId: '',
      userValue: '', // 传递的首页查询条件
      amount,
      orderId,
      accountId, // 账户id
      customerNumber,
      isOver: false,
      platformStatus: {
        enable: true, // 默认开启
        msg: '', // 当平台停用时的消息提示
      }, // 平台启用状态
    }
  },
  created() {
    this.hideshare()
    this.getAccountInfo()
  },
  methods: {
    onBridgeReady() {
      WeixinJSBridge.call('hideOptionMenu')
    },
    hideshare() {
      if (typeof WeixinJSBridge == 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady)
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady)
        }
      } else {
        this.onBridgeReady()
      }
    },
    // 获取用户信息
    getAccountInfo() {
      let data = this.customerNumber !== '' ? this.customerNumber : this.accountId
      getCustomerNumber4AppVo({ queryParam: data })
        .then((res) => {
          if (+res.code === 9200) {
            this.customerName = res.data.customerName
            this.idCard = res.data.idCard
            this.userValue = this.customerNumber !== '' ? this.customerNumber : this.accountId
            if (this.customerNumber !== '') {
              const arr = res.data.detailList
              arr.find((item) => {
                if (item.customerNumber === this.customerNumber) {
                  this.customerNumberId = item.customerNumberId
                  this.fullAddress = item.fullAddress
                  this.deviceNumber = item.deviceNumber
                }
              })
            } else {
              this.customerAccountId = res.data.customerAccountId
            }
            console.log(this.customerAccountId, '==', this.customerNumberId, '==', this.userValue)
          } else {
            Toast.fail(res.msg)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    surePay() {
      getPlatformStatus().then((res) => {
        let platform = this.util.getTranscateRst(res)
        this.platformStatus.enable = platform.state
        this.platformStatus.msg = platform.msg
        if (platform.state) {
          this.isOver = true
          payOrder({
            orderId: this.orderId,
            code: this.code, // 微信用户授权code
          }).then((res) => {
            if (+res.code === 9200) {
              // 获取支付参数
              this.handlePay(res.data)
            } else {
              this.isOver = false
              Toast(res.msg)
            }
          })
        } else {
          Notify({
            type: 'danger',
            message: this.platformStatus.msg,
          })
        }
      })
    },
    // 拉起支付
    handlePay(data) {
      const that = this
      function onBridgeReady() {
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          {
            ...data,
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 跳转缴费记录页
              that.$router.push({
                name: 'paymentRecords',
                query: {
                  accountId: that.customerAccountId,
                  customerNumberId: that.customerNumberId,
                  keyword: that.userValue,
                },
              })
            } else {
              Toast.fail('支付失败,请返回重新支付')
              this.isOver = false
              that.$router.go(-1)
            }
          },
        )
      }
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
        }
      } else {
        onBridgeReady()
      }
    },

    finish() {
      this.isOver = true
      Toast('支付超时')
    },
    /**
     * 微信公众号是否可访问
     */
    loadPlatformStatus: function () {
      getPlatformStatus().then((res) => {
        let platform = this.util.getTranscateRst(res)
        this.platformStatus.enable = platform.state
        this.platformStatus.msg = platform.msg
      })
    },
  },
}
</script>

<style lang="less">
.pay-at-once {
  .main-height-x {
    padding: 20px 10px;
    box-sizing: border-box;
  }
  .card {
    color: #fff;
    background: #fff;
    .pay-way {
      color: #666;
      display: inline-block;
      padding: 10px;
    }
    .name-info {
      padding: 10px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      p {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
      }
    }
    .pay-price {
      background: #fff;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      padding: 20px 0;
      p {
        display: flex;
        justify-content: center;
        b {
          margin: 0 5px;
        }
      }
      .remain-time {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        span {
          display: inline-block;
          border-radius: 21px;
          background-color: rgba(245, 245, 245, 100);
          color: #666666;
          padding: 2px 10px;
          display: flex;
        }
      }
    }
  }
  .self-icon {
    width: 32px;
    height: 32px;
    font-size: 22px;
  }
  .btn-wrap-x {
    margin-top: 10px;
    width: 100%;
    .self-btn {
      width: 100%;
      height: 40px;
    }
  }
}
</style>
